/**
 * Videos Database can be added here.
 * You can add videos of your wish with different attributes
 * */

export const videos = [
	{
		_id: 'uLwFYPsS_Zc',
		title: 'The PS5 Unboxing - Sony PlayStation 5 Next Gen Console',
		creator: 'TheRelaxingEnd',
		categoryName: 'Unboxing',
		description:
			'Unboxing PS5 next gen console. Opening PlayStation 5 box. Next Generation of PlayStation gaming. DualSense haptic feedback controller.',
		date: 'Oct 30, 2020',
		views: '9.8M',
	},
	{
		_id: 't3pCk-MtJIE',
		title: 'The PS4 Launch Console Unboxing in 2021 + Rare PlayStation 4 Press Kit',
		creator: 'TheRelaxingEnd',
		categoryName: 'Unboxing',
		description: 'Star Wars: Republic Commando is available now - Relive the legendary campaign on Nintendo ',
		date: 'Apr 8, 2021',
		views: '12M',
	},
	{
		_id: 'RtoUS7EHQXw',
		title: 'New Xbox Series S Unboxing | Budget 4K Gaming ?',
		creator: 'Tech Burner',
		categoryName: 'Unboxing',
		description: 'Xbox Majedaar hai ki nahi?',
		date: 'Nov 17, 2020',
		views: '1.2M',
	},

	{
		_id: '1IY7otlob34',
		title: 'WATCH DOGS LEGION UNBOXING | zoef games | Hindi',
		creator: 'Zoef Games',
		categoryName: 'Unboxing',
		description: 'WATCH DOGS LEGION UNBOXING.....',
		date: 'Oct 29, 2020',
		views: '466',
	},

	{
		_id: '_U1UEOeMttE',
		title: 'Starting of 2021 With a New Beast PC',
		creator: 'Shubhuuu',
		categoryName: 'Unboxing',
		description: '',
		date: 'Jan 2, 2021',
		views: '2.6M',
	},

	{
		_id: 'VGt-BZ-SxGI',
		title: 'Minecraft Part 1',
		creator: 'PewDiePie',
		categoryName: 'Gameplay',
		description: 'we go back to our minecraft gameplay roots',
		date: 'Jun 21, 2019',
		views: '51M',
	},
	{
		_id: 'uUkKBtLs1Ok',
		title: 'DOOM Eternal NIGHTMARE MODE | Xyaa livestream',
		creator: 'Xyaa',
		categoryName: 'Gameplay',
		description: '',
		date: 'Mar 20, 2020',
		views: '14K',
	},

	{
		_id: '-R58pnOyuvc',
		title: '200 IQ hide and seek Among us w/GaurgeX | stream Highlight',
		creator: 'Zoef Games',
		categoryName: 'Gameplay',
		description: '200 IQ hide and seek #Amongus w/GaurgeX ',
		date: 'Nov 1, 2020',
		views: '119',
	},
	{
		_id: 'RcO2bNGOvFA',
		title: 'Funny Moments from Among us | Twitch Highlight | Zoef Games',
		creator: 'Zoef Games',
		categoryName: 'Gameplay',
		description: 'Funny Moments from Among us.This is  a Twitch Stream highlights.You can Check out full Stream here:',
		date: 'Oct 3, 2020',
		views: '170',
	},

	{
		_id: 'n4xPu72QJsI',
		title: 'HORIZON FORBIDDEN WEST PS5 Walkthrough Gameplay Part 1',
		creator: 'theRadBrad',
		categoryName: 'Gameplay',
		description: 'Horizon Forbidden West PS5 Walkthrough Gameplay 1 includes a Review, ',
		date: 'Feb 17, 2022',
		views: '1.7M',
	},

	{
		_id: 'C1ObitoLwhM',
		title: 'Trap Adventure 2 - WHO MADE THIS GAME AND WHY',
		creator: 'PewDiePie',
		categoryName: 'Rage Gameplay',
		description: 'We play trap adventure 2 on ios, Download trap adventure 2 for free:',
		date: 'Jan 27, 2018',
		views: '32M',
	},

	{
		_id: 'YElZT7KWqcU',
		title: 'THE RAGE RETURNS - Jump King',
		creator: 'JJ Olatunji',
		categoryName: 'Rage Gameplay',
		description: "KSI: Can't Lose available worldwide ",
		date: 'Apr 23, 2020',
		views: '5.1M',
	},

	{
		_id: '2oSGqlMe2yA',
		title: 'This Game Is Terrible!!!',
		creator: 'JJ Olatunji',
		categoryName: 'Rage Gameplay',
		description: 'TRASH',
		date: 'Jan 12, 2019',
		views: '6.3M',
	},

	{
		_id: '9EVdZ_xDbOs',
		title: 'HARDEST RAGE GAME EVER MADE!',
		creator: 'Jelly',
		categoryName: 'Rage Gameplay',
		description: 'HARDEST RAGE GAME EVER MADE! If you enjoyed this video check out gaming videos here: ',
		date: 'Nov 18, 2017',
		views: '3.9M',
	},

	{
		_id: 'd6sk51OeSOg',
		title: 'Pig Killed me with a Saw | PIGSAW | Zoef Games | Hindi Gameplay',
		creator: 'Zoef Games',
		categoryName: 'Rage Gameplay',
		description: 'Pig Killed me with a Saw.',
		date: 'Oct 15, 2020',
		views: '613',
	},

	{
		_id: 'TpHwZD2tnSY',
		title: 'The MOST LUXURIOUS GAMING FACILITY in INDIA',
		creator: 'S8UL',
		categoryName: 'Vlog',
		description: 'S8UL IS PROUD TO SHOWCASE ONE OF THE MOST LUXURIOUS gaming facility in India. ',
		date: 'Apr 2, 2021',
		views: '8M',
	},

	{
		_id: 'V4iHiIJmlo4',
		title: 'ULTIMATE $1,000,000 HOUSE TOUR!',
		creator: 'Jelly',
		categoryName: 'Vlog',
		description: '',
		date: 'Sep 24, 2017',
		views: '471K',
	},

	{
		_id: 'V4Y5_GmCfnE',
		title: 'I BOUGHT MY DREAM HOUSE!',
		creator: 'Vikkstar',
		categoryName: 'Vlog',
		description: 'A video tour of my brand new house. Enjoy!',
		date: 'Aug 28, 2021',
		views: '6.4M',
	},

	{
		_id: '__waU3NhyY0',
		title: 'My New $3,000,000 Apartment Tour',
		creator: 'behzinga',
		categoryName: 'Vlog',
		description: 'Video Uploaded by Behzinga, Beh2inga.',
		date: 'Sep 9, 2020',
		views: '2.9M',
	},

	{
		_id: 'HUT5reWPUQ0',
		title: 'W2S NEW HOUSE TOUR',
		creator: 'W2SPlays',
		categoryName: 'Vlog',
		description: 'W2S NEW HOUSE SETUP TOUR HAPPY DAYS',
		date: 'Dec 26, 2020',
		views: '5.6M',
	},
	{
		_id: 'O-w-NKMowIY',
		title: 'Valorant Skin Challenge | Zoef Games',
		creator: 'Zoef Games',
		categoryName: 'Challenges',
		description: "In today's video, I created a mini-game in Valorant for my friends in which the winner will get a Vandal skin.",
		date: 'Aug 23, 2021',
		views: '78',
	},
	{
		_id: '6PsZD_y-T7A',
		title: 'TRY NOT TO GET SCARED CHALLENGE!!',
		creator: 'Pewdiepie',
		categoryName: 'Challenges',
		description: 'ヅTry not to get scared the most difficult youtube challenge of all timeヅ',
		date: 'Jan 26, 2017',
		views: '17M',
	},

	{
		_id: '5stsqpfsyTI',
		title: 'THE ULTIMATE GTA 5 CHALLENGE',
		creator: 'Mythpat',
		categoryName: 'Challenges',
		description:
			"In today's video PROMAN does the ultimate gta 5 challenge map in air. We do stunts in gta 5 and this is a very funny video of Grand theft Auto 5.",
		date: 'Jun 17, 2020',
		views: '34M',
	},

	{
		_id: '07-peTQJBTs',
		title: 'TRY NOT TO LAUGH CHALLENGE! #1',
		creator: 'PewDiePie',
		categoryName: 'Challenges',
		description: 'Also I get hacked during this video...',
		date: 'Mar 20, 2016',
		views: '23M',
	},

	{
		_id: '4KhEDovoDP0',
		title: "EVERY FALL GUYS LOSS = HERSHEY's SYRUP + TOMATO SAUCE",
		creator: 'Zoef Games',
		categoryName: 'Challenges',
		description:
			"EVERY FALL GUYS LOSS = HERSHEY's SYRUP + TOMATO SAUCE. In this video i will eat hershey's syrup+tomato sauce every time i lose.",
		date: 'Sep 3, 2020',
		views: '120',
	},

	{
		_id: 'Nmk3VHfgkLk',
		title: 'APEX LEGENDS NHK?!?! | Zoef games',
		creator: 'Zoef Games',
		categoryName: 'FPS',
		description: 'Hey, so I am back. More content soon. Trying out apex legends  HELP',
		date: 'Aug 28, 2021',
		views: '57',
	},

	{
		_id: 'IABIU27z7_k',
		title: 'M24 MADNESS (FULL GAME)',
		creator: 'Shroud',
		categoryName: 'FPS',
		description: 'All highlights are from my stream :D',
		date: 'Mar 21, 2018',
		views: '4.8M',
	},

	{
		_id: 'It0o9T5OS_4',
		title: 'SHROUD INSANE 30 KILL GAME!',
		creator: 'Shroud',
		categoryName: 'FPS',
		description: 'All highlights are from my stream.',
		date: 'Nov 9, 2017',
		views: '3.4M',
	},
	{
		_id: 'HJPEkBHxoSE',
		title: 'A VALORANT RANKED SPEEDRUN',
		creator: 'Shroud',
		categoryName: 'FPS',
		description: 'All highlights are from my stream :D',
		date: 'Apr 13, 2022',
		views: '218K',
	},

	{
		_id: 'jnSTblucp7A',
		title: 'THE NEW YORU IS INSANE',
		creator: 'Shroud',
		categoryName: 'FPS',
		description: '#shroud #valorant',
		date: 'Apr 19, 2022',
		views: '142K',
	},
	{
		_id: 'SzuoB4_RpT4',
		title: "Marvel's Avengers Review(*NO SPOILERS*)",
		creator: 'Zoef Games',
		categoryName: 'Podcast',
		description:
			"This is a #MarvelsAvengersReview (*NO SPOILERS*)  in #hindi. Marvel's Avengers is an action role-playing brawler video game co-developed by Crystal Dynamics and published by Square Enix. Based on the Marvel Comics superhero team the Avengers, the game is mainly inspired by the Marvel Cinematic Universe's iteration of the group, as well as the long-running comic book mythology.",
		date: 'Sep 9, 2020',
		views: '55',
	},
	{
		_id: '9M9EXxuPy6E',
		title: '2020 GAMES TIER LIST | VALORANT, AMONG US, CYBERPUNK| PING AND LAG (PODCAST)',
		creator: 'Zoef Games',
		categoryName: 'Podcast',
		description: '2020 GAMES TIER LIST | VALORANT, AMONG US, CYBERPUNK.... WHO DESERVES #GAMEOFTHEYEAR ?',
		date: 'Sep 9, 2020',
		views: '47 ',
	},
	{
		_id: 'Wzc8_GZDSR0',
		title: 'Roasting our Terrible Taste in Games | Trash Taste #45',
		creator: 'Trash Taste',
		categoryName: 'Podcast',
		description: '',
		date: 'Apr 24, 2021',
		views: '1.7M',
	},
	{
		_id: 'rhn3C9gCxmQ',
		title: "What's The Best Video Game Of The Decade? | WhatCulture Gaming Podcast",
		creator: 'WhatCulture Gaming',
		categoryName: 'Podcast',
		description:
			"From 2010 to 2019, Scott, Josh and Ash get the ball rolling.Don't forget to Subscribe to the WhatCulture Gaming Podcasts YouTube channel! ",
		date: 'Sep 16, 2019',
		views: '106K',
	},
	{
		_id: 'Aj4IXQf9L4Y',
		title: '2022 is great for Video Games (so far) w YongYea, SkillUp & Jake Baldino',
		creator: 'Alanah Pearce',
		categoryName: 'Podcast',
		description: 'A discussion on how we feel about VIDEO GAMES in 2022, so far!',
		date: 'Sep 16, 2019',
		views: '132K',
	},
	{
		_id: 'zUDlDQ4djPs',
		title: 'SIDEMEN PLAY THE SCARIEST GAME EVER',
		creator: 'MoreSidemen',
		categoryName: 'ScaryGames',
		description: '',
		date: 'Oct 31, 2021',
		views: '3.8M',
	},
	{
		_id: 'FhKHn5r1mrA',
		title: "MY WORST JUMPSCARE - (Sophie's Curse)",
		creator: 'PewDiePie',
		categoryName: 'ScaryGames',
		description: 'Sophies curse, hate that game T_T. Thanks for da like ★~(◡﹏◕✿)',
		date: 'Apr 19, 2016',
		views: '17M',
	},
	{
		_id: 'KQ5_jumTeT4',
		title: 'Outlast 2 - Part 1 - SO HYPED FOR THIS',
		creator: 'PewDiePie',
		categoryName: 'ScaryGames',
		description: 'ヅlets play of outlast 2 its gonna be a full playthrough bbyヅ',
		date: 'Apr 24, 2017',
		views: '11M',
	},
	{
		_id: 'ShGdxIQzLBY',
		title: 'WHY DID I EVEN PLAY THIS || AmnesiaThe Dark Descent(Hindi)',
		creator: 'Zoef Games',
		categoryName: 'ScaryGames',
		description:
			'WHY DID I EVEN PLAY THIS.\nI am an #IndianGamer, playing #AmnesiaTheDarkDescent with #HindiCommentary.\nI am not really a big fan of #horrorGames but i still wanted to try amnesia out',
		date: 'Sep 14, 2020',
		views: '87',
	},
	{
		_id: 'jdKOfueLzmU',
		title: 'playing the SCARIEST Game in the World',
		creator: 'Mythpat',
		categoryName: 'ScaryGames',
		description: 'Today I play the scariest game in the world called Death Trips. Hope you enjoy this horror game.',
		date: 'Apr 13, 2022',
		views: '2M',
	},
];
